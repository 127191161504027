import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Feedback from '../components/feedback'

import styles from '../components/article.module.scss'


import Collapsible from '../components/collapsible-element'

import LeadSection from '../components/lead-section'

import ArticleListing from '../components/article-listing'

import MetadataGenerator from '../components/metadata-generator'

import articleListStyles from '../components/article-listing-styling.module.scss'










class PageDefaultCategory extends React.Component {
  render() {

    const post = get(this.props, 'data.contentfulSupportSiteTopic')
    const product = get(this.props, 'data.contentfulSupportSiteProduct')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const articles = get(this, 'props.data.allContentfulSupportSiteArticle.edges')
    const pages = get(this, 'props.data.allContentfulSupportSitePage.edges')
    const headerImageTest = get(this, 'props.data.contentfulAsset')



    return (
      <Layout headerImage={headerImageTest} location={this.props.location}>
        <section className={styles.dark}>
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <MetadataGenerator 
            title={post.title} 
            siteTitle={siteTitle}  
            urlBase={this.props.location.origin}
            url={this.props.location.href}/>
                   
                    <LeadSection eyebrow={product.title} title={post.title}/>
              <ol className={articleListStyles.articleList} start="1">
              {pages.map(({ node }, i) => {
                return (
                  <li id={i + 1} key={node.slug}>
                    <ArticleListing article={node} />
                  </li>
                )
              })}
              {articles.map(({ node }, i) => {
                return (
                  <li id={i + 1} key={node.slug}>
                    <ArticleListing article={node} />
                  </li>
                )
              })}
            </ol>
        </section>
      </Layout>
    )
  }
}

export default PageDefaultCategory

export const pageQuery = graphql`
  query PageInformationCategory($slug: String!, $productSlug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulSupportSiteTopic(slug: { eq: $slug }) {
      title
      slug
    }
    contentfulSupportSiteProduct(slug: { eq: $productSlug }) {
      title
      slug
    }
    allContentfulSupportSiteArticle(sort: { fields: [updatedAt], order: DESC }, filter:{
      articleCategoryTopic:{slug:{eq: $slug}},
      productReference:{slug:{eq: $productSlug}}} ) {
      edges {
        node {
          title
          slug
          createdAt (formatString: "MMMM Do, YYYY")
          updatedAt (formatString: "MMMM Do, YYYY")
          description {
            childMarkdownRemark {
              html
            }
          }
          articleBody {
            json 
          }
             productReference {
            ... on ContentfulSupportSiteProduct {
              title
              slug
            }
          }
             articleCategoryTopic {
            ... on ContentfulSupportSiteTopic {
              title
              slug
            }
          }
          manufacturerPlatform {
            title
            logo {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
    allContentfulSupportSitePage(
      sort: { fields: [updatedAt], order: DESC }, 
      filter:{
        pageTopic:{slug:{eq: $slug}},
        pageProduct:{slug:{eq: $productSlug}},
        slug:{ne:"placeholder"}
      }
    
      ) {
      edges {
        node {
          title
          slug
          createdAt (formatString: "MMMM Do, YYYY")
          updatedAt (formatString: "MMMM Do, YYYY")
          description {
            childMarkdownRemark {
              html
            }
          }
          body {
            json 
          }
             pageProduct {
            ... on ContentfulSupportSiteProduct {
              title
              slug
            }
          }
             pageTopic {
            ... on ContentfulSupportSiteTopic {
              title
              slug
            }
          }

        }
      }
    }
    contentfulAsset(file: {fileName: {eq: "youview-support-background.jpg"}}, fluid: {}) {
      fixed(width: 1800, quality: 100) {
        base64
        aspectRatio
        width
        height
        src
        srcSet
        srcWebp
        srcSetWebp
      }
    }
  }
`
